import { Outlet } from "@remix-run/react";

import { ErrorComponent } from "~/components/error";

export default function AuthLayout() {
  return (
    <>
      <div className="flex min-h-dvh w-full flex-col items-center justify-center bg-secondary p-6 md:p-0">
        <Outlet />
      </div>
    </>
  );
}

export function ErrorBoundary() {
  return <ErrorComponent />;
}
